<template>
  <base-section id="awards-detail">
    <v-container class="mt-n15">
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <template>
          <v-card
            class="mx-auto"
            v-for="data in posts"
            :key="data.web_news_id"
            flat
          >
            <v-row justify="space-between">
            <v-col 
              cols="7"
              md="7"
            >
            <v-img
              :src="data.web_news_src_url"
              contain
              :alt="$route.params.url"
            ></v-img>
            </v-col>
            <v-col 
              cols="5"
              md="5"
              class="mt-n6"
            >
            <div class="headline my-5 fourth--text"> {{ title }} </div>
            <div class="body-2 accent--text">{{ FormatDateFullOnlyDate(data.web_news_cdate) }}</div>
            <small>Bagikan : </small>
            <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :hashtags="sharing.hashtags"
              >
                <v-icon :color="network.color">{{ network.icon }}</v-icon>
              </ShareNetwork>
            <v-alert
              border="top"
              colored-border
              color="fourth"
              class="grey lighten-4 body-2 text-justify"
            >
            <div v-html="data.web_news_detail"></div>
            </v-alert>
            </v-col>
            </v-row>
            <v-card-actions>
            </v-card-actions>
          </v-card>
        </template>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAwardsDetail',
    data: () => ({
          title: '',
          share: false,
          posts: [],
          sharing: {
            url: '',
            title: '',
            hashtags: 'takafulumum,asuransisyariah',
          },
          networks: [
            { network: 'email', name: 'Email', icon: 'mdi-email', color: 'primary' },
            { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: 'primary' },
            { network: 'telegram', name: 'Telegram', icon: 'mdi-telegram', color: 'primary' },
            { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: 'primary' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: 'primary' },
          ]
      }),
    created () {
      this.List()
    },
    watch: {
        "$route.params.url"(val) {
          console.log(val)
          this.List();
        },
        title: function (val, old) {
            console.log(old)
            document.title     = val
            this.sharing.title = this.$functions.AppURL() +""+ this.$router.currentRoute.path
        }
    },
    methods: {
      List () {
        this.loading  = true
        let title = this.$route.params.url
        let formdata = {
              web_news_id: title
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebNewsList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.posts = response.data
            this.title = this.posts[0].web_news_name
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
      },
      FormatDateFullOnlyDate (date) {
        return this.$functions.FormatDateFullOnlyDate(date)
      },
      Detail (url) {
        this.$router.push({path: 'berita-detail', query: { url: url }})
      },
      Share () {
        window.open('https://wa.me/?text='+encodeURIComponent(this.$route.params.url))
      }
    }
  }
</script>
